.custom-select-container {
  max-width: 100%;
  font-size: 14px;
  color: #aaa;
  margin-top: 3rem;
  margin: 0 8px;

  .custom-select {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .select-label {
      color: black;
      margin-right: 4px;
    }
    .select-input {
      position: relative;
      width: 100%;
      .select-button {
        width: 100%;
        font-size: 14px;
        background-color: transparent;
        border-radius: 0.25rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 6px;
        color: black;
        border: 1px solid #aaa;
        .selected-option {
          text-align: left;
        }
        .arrow {
          margin-left: 6px;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 6px solid #aaa;
          transition: transform ease-in-out 0.3s;
        }
        &.no-borders {
          border: unset;
        }
        &.no-padding {
          padding: unset;
        }
        &.disabled {
          cursor: default;
          color: #aaa;
          .arrow {
            border-top: 6px solid #d8d8d8;
          }
        }
      }
      .select-dropdown {
        position: absolute;
        list-style: none;
        top: calc(100% - 4px);
        left: 0;
        width: 100%;
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #dcdcdc;
        border-radius: 8px;
        padding: 8px;
        margin-top: 10px;
        max-height: 200px;
        overflow-y: auto;
        transition: 0.5s ease;
        color: black;
        z-index: 9;
        &:focus-within {
          box-shadow: 0 10px 25px rgba(94, 108, 233, 0.6);
        }
        li {
          position: relative;
          cursor: pointer;
          display: flex;
          gap: 1rem;
          align-items: center;
          border-bottom: 1px solid #dcdcdc;
          input {
            cursor: pointer;
          }
          label {
            width: 100%;
            padding: 6px 0;
            cursor: pointer;
          }
        }
        .selected-option {
          font-weight: bold;
          color: #3478f6;
        }
        :last-child {
          border: none;
        }
        &::-webkit-scrollbar {
          width: 7px;
        }
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 25px;
        }
        &::-webkit-scrollbar-thumb {
          background: #ccc;
          border-radius: 25px;
        }
        li:hover {
          background-color: #f2f2f2;
        }
        input[type='radio'] {
          position: absolute;
          left: 0;
          opacity: 0;
        }
      }
    }
  }
}
