:root {
  --primary: #2b6747;
  --secondary: #c9dfd3;
  --tertiary: #f0f8f4;

  --text-black: #424242;
  --text-gray: #858e93;

  --detail-gray: #a1acb2;
  --detail-border: #cdcdcd;

  --warning-error: #ff4949;
  --warning-success: #4aa04a;
  --warning-orange: #fa9f47;
  --warning-blue: #4994ee;
  --warning-disable: #dddddd;

  --bg-white: #ffffff;
  --bg-gray: #f5f5f5;
  --bg-yellow: #fff3d6;
  --bg-red: #fff0f0;
  --bg-green: #cdfcbd;
  --bg-blue: #e4effd;

  --box-shadow-small: 0px 2px 4px #b3b3b3;
}

body {
  margin: 0;
  background-color: #f6f5fb;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:hover {
  color: #2cda9b;
}

.container {
  background-color: transparent;
  max-width: none !important;
}

.bg-primary {
  background-color: #2cda9b !important;
}

.form-control::placeholder {
  color: #c2cace !important;
}

.badge.bg-primary,
.dropdown-item:active {
  background-color: #2cda9b !important;
}

.badge {
  border-radius: 72px;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: 2CDA9B (94, 103, 123, 0.25);
}

.form-label {
  color: #2cda9b;
}

.levelHigh {
  margin-left: 3px;
  color: #2cda9b;
}

.levelMedium {
  margin-left: 3px;
  color: #fccf1a;
}

.levelLow {
  margin-left: 3px;
  color: #ff5216;
}

.decisionValue.accept {
  color: #2cda9b;
}

.decisionValue.reject {
  color: #ff5216;
}

.react-tooltip {
  z-index: 9999;
}
